.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

* {
    font-family: monospace;
}

.text-center {
    text-align: center;
}

.align-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: flex-end;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.picture-preview {
    display: grid
}

.picture-preview img {
    grid-row: 1;
    grid-column: 1;
    max-height: 65vh;
}

.mapmaker_root {
    display: grid;
    grid-template-rows:8fr 1fr;
    max-height: 100vh;
    max-width: 100vw;
}
.map-preview *{
    max-width: 100vw;
}

.steps-bar {
    display: flex;
    justify-content: center;
}
a.btn{
    padding:5px;
    line-height: 1.5em;
    text-decoration: none;
    border-radius: 15px;
}
.btn-facebook{
    background-color: #4267B2;
    color:white;

}
.btn-instagram{
    background-color: #C13584;
    color:white;
}
.btn-highlight {
    background-color: #2980b9;
    color: white;
}

.btn-orange {
    background-color: #ee9b00;
    color: white;
}
button.green{
    color: white;
    background-color: rgba(0, 128, 0, 0.56);
}
button.green:disabled{
    background-color: rgba(9, 17, 9, 0.56);
    cursor: unset;
    box-shadow: unset;
    color: darkgrey;
}

button {
    font-weight: bold;
    font-size: large;
    padding: 1%;
    background: None;
    border-radius: 5px;
    border: none;
    box-shadow: 0 1px 3px 0 #a2a2a29c;
    margin: 11px;
}

button.line {
    border: none;
    font-weight: normal;
    box-shadow: none;
    font-style: unset;
    text-decoration: underline;
}

button.line:hover {
    box-shadow: none;
    font-weight: bold;
}

button:hover {
    cursor: pointer;
    box-shadow: 0 1px 16px 0 #a2a2a29c;

}


.step-view {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}

.placement-view {
    display: block;
    width: 95%;
}

.navigation {
    display: grid;
    grid-template-columns: 1fr  10fr;
}

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    height: 100vh;
}


.map-header {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    justify-items: center;
}

.map-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contours-preview {
    display: grid;
    grid-template-rows: 1fr 10fr;
    height: 100%;
}

.contours-preview button {
    padding: 1%;
}

/* The animation code */
@keyframes filling {

    from {
        background-position: -100%;
    }
    50% {
        background-position: -200%;
    }
    to {
        background-position: -300%;
    }

}

button.loading {
    background: linear-gradient(to right, rgba(41, 128, 185, 0.6) 50%, rgba(255, 255, 255, 0.7) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    animation-name: filling;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.loading {
    background: linear-gradient(to right, rgba(41, 128, 185, 0.6) 50%, rgba(41, 128, 185, 0.93) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    animation-name: filling;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    color: white;
    border-radius: 5px;
    padding: 5px;
}

.btn-highlight.loading {
    background: linear-gradient(to right, rgba(41, 128, 185, 0.6) 50%, rgba(41, 128, 185, 0.93) 50%);
    background-size: 200% 100%;
    background-position: -100%;
    animation-name: filling;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/


.loading-overlay{
    position: absolute;
    background: #27272782;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-weight: bolder;
    font-size: x-large;
    border-bottom-right-radius: 32px;
    border-top-right-radius: 32px;
}
@media (min-width: 768px) {
    .map-preview{
        height: 100vh;
    }
}
.grow-transition-enter{
height: 0;
}
.grow-transition-active {
    transition: height 1000ms;
}
.config-panel-enter {
    transform: translateX(-100vh);
    opacity: 0;
}
.config-panel-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition:  transform 500ms, opacity 200ms;
}
.config-panel-exit {
    transform: translateX(0);
    opacity: 1;
    position: fixed;
}
.config-panel-exit-active {
    transform: translateX(-100vh);
    opacity: 0;
    transition:  transform 500ms, opacity 200ms;
}
.fade-transition-enter{
    opacity: 0;
}
.fade-transition-enter-active{
    opacity: 1;
    transition: opacity 200ms;
}

@media (min-width: 320px) and (max-width: 767px) {
    .map-preview{
        height: 100%;
    }
    .loading-overlay{
        border-radius: 0;
    }


}
.config-panel-container.final_preview_config{
    top:unset;
    left:0;
    right:0;
    bottom:15vh;
    align-items: center;
    justify-content: flex-end;
}
.config-panel.final_preview_config{
    border-radius: 20px;
    max-width: 70%;
    width: unset;
}
.map-preview {
    /*height: 100vh;*/
    background: #f9f9f9;
}
.map-preview>div{height: 100%}
.stack-preview > img {
    position: absolute;
    max-width: 100vw;
}

.main-action-btn {
    margin: auto;
    font-size: 1.75em;
    padding: 0.5em;
    border-radius: 16px;
    margin-top: 20px;
}

.placement-step-config {
    display: flex;
    flex-direction: column;
}

.preview-miniature {
    padding: 7px;
}
div.final_preview {

    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

img.final_preview {
    margin: auto;
    max-height: 70vh;
    max-width: 90%;
    height: auto;
}

.preview-miniature img {
    width: 58px;
    height: 58px;
    background-color: white;
    object-fit: cover;
    border-radius: 5px;
    border: solid 1px #aadaff94;
}

.level-miniature {
    padding: 8px;
}

.horizontal-galery {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.vertical-galery {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.vertical-galery-3-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

/* width */
.horizontal-galery::-webkit-scrollbar {
    height: 5px;
}

/* Track */
.horizontal-galery::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 2px;
}

/* Handle */
.horizontal-galery::-webkit-scrollbar-thumb {
    background: #8888888a;
    border-radius: 4px;
}

/* Handle on hover */
.horizontal-galery::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.valid.vignette {
    background-color: rgba(0, 128, 0, 0.56);
    color: white;
    padding: 4px;
    border-radius: 10px;
}

.config-section {
    border-bottom: solid 1px darkgray;
}
.place-search{
    padding-top: 15px;

}
input{
    border:none;
    border-bottom: solid 1px;

}

/* The popup bubble styling. */
.map-text {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the bubble. */
    background-color: #2980b9;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-family: sans-serif;
    overflow-y: auto;
    max-height: 60px;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.map-text-anchor {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: 8px;
    left: 0;
}



/* JavaScript will position this div at the bottom of the popup tip. */
.map-text-container {
    cursor: auto;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
}
.error-screen{
    color: #2980b9
}
.small-line > button{
    margin-top: 0;
    margin-bottom: 0;
}
.example-map{
    height: 100vh;
    width: 100vw;

}

.clipped-map-tiles{
    clip-path: url(#clipPath);
}

.gallery{
    display: grid;
    grid-auto-columns: fit-content(20%);
    grid-template-columns: repeat(auto-fill, 200px);
}
.gallery  .job-list  h5{
    margin: 0;
}
.gallery .job-list *{
    font-size: 0.4em;
}


.gallery .map-preview{
    max-height: 100px;
}
.gallery h4{
    margin: 0;
}
.gallery > *{
    font-weight: bold;
    font-size: large;
    padding: 1%;
    background: None;
    border-radius: 5px;
    border: none;
    box-shadow: 0 1px 3px 0 #a2a2a29c;
    margin: 11px;
}
.gallery .overlay-list *{
    margin:0 1em;
}
.gallery .overlay-list{
    display: flex;
    flex-wrap: wrap;
    font-size: 0.7em;
}
