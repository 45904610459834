.admin-config-panel-container {
    z-index: 100000;
    /*position: absolute;*/
    display: flex;
    flex-direction: column;
    pointer-events: none
}

.admin-config-panel {
    padding: 5%;
    pointer-events: all;
    position: relative;
}
@media (min-width: 768px) {
    .admin-config-panel-container {
        height: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        right:0;

    }
    .admin-config-panel {
        top: 7vh;
        bottom: 7vh;
        background: white;

        border-radius:  32px 0 0 32px ;
        width: 20vw;
        padding: 0;
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .admin-config-panel-container {
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
    }

    .admin-config-panel {
        display: flex;
        align-content: space-between;
        flex-direction: column;
        justify-content: space-between;
        bottom: 0;
        background: white;
        border-radius: 32px 32px 0 0;
        left: 0;
        right: 0;

    }
}

.job-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
